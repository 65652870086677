
import { defineComponent } from "vue";
import dateFormat from "@/utils/dateFormat";

export default defineComponent({

  name: "Letter",
  props: {
    letter: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    function formatAvatar(id: string, item: any) {
      let src = "";
      if (!Number(id)) {
        src =
          "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/logo20200923.svg";
      } else {
        src = item.user.userImg;
      }
      return src
    }
    return {
      dateFormat,
      formatAvatar,
    };
  },
});
