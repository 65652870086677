<template>
  <main>
  <menu-tab :menu="menu" @change="selectMenu"></menu-tab>

    <div class="list-container">
      <list url="/M/Server/GetNoticeLasttz" :showLoading="true" :show="currentMenuItem == menu[0]">
        <template v-slot:default="data">
          <notice :notice="data"></notice>
        </template>
      </list>
      <list url="/M/Server/GetNoticeLastsx" :showLoading="true" :show="currentMenuItem == menu[1]">
        <template v-slot:default="data">
          <letter :letter="data"></letter>
        </template>
      </list>
      <list url="/M/Server/GetNoticeLastpl" :showLoading="true" :show="currentMenuItem == menu[2]">
        <template v-slot:default="data">
          <comment :comment="data"></comment>
        </template>
      </list>
      <list url="/M/Server/GetNoticeLastdz" :showLoading="true" :show="currentMenuItem == menu[3]">
        <template v-slot:default="data">
          <support :support="data"></support>
        </template>
      </list>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
// components
import list from "@/components/common/list.vue";
import Notice from "@/components/message/Notice.vue";
import Letter from "@/components/message/Letter.vue";
import Comment from "@/components/message/Comment.vue";
import Support from "@/components/message/Support.vue";
import MenuTab from "@/components/common/MenuTab.vue";

export default defineComponent({
  name: "News",
  components: {
    list,
    Notice,
    Letter,
    Comment,
    Support,
    MenuTab,
  },
  setup() {
    //  切换列表菜单
    type Menu = "通知" | "私信" | "评论" | "点赞";
    const menu: Menu[] = ["通知", "私信", "评论", "点赞"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }

    return {
      menu,
      currentMenuItem,
      selectMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
main{
    background-color: #f4f4f4;
}
</style>
